<template>
<div>
    <div>
        <p style="margin:7.8pt 0pt 0pt; orphans:0; text-align:center; widows:0">
            <span style="font-family:等线; font-size:14pt; font-weight:bold">儿童个人信息保护规则</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt; orphans:0; text-align:right; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">生效日期：</span>
            <span style="font-family:宋体; font-size:10.5pt">2022</span>
            <span style="font-family:宋体; font-size:10.5pt">年</span>
            <span style="font-family:宋体; font-size:10.5pt">6</span>
            <span style="font-family:宋体; font-size:10.5pt">月</span>
            <span style="font-family:宋体; font-size:10.5pt">30</span>
            <span style="font-family:宋体; font-size:10.5pt">日</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt; orphans:0; text-align:right; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt; orphans:0; text-align:right; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">联想致力于保护使用我们产品和/或服务的儿童的个人信息。在</span>
            <a name="_cmntref1">
                <span style="font-family:宋体; font-size:10.5pt">联想</span>
            </a>
            <!-- <a style="color:#800080" href="https://educenter.lenovo.com/Account/LenovoAbout"> -->
            <a style="color:#800080" :href="privacyUrl">
                <span style="color:#800080; font-family:宋体; font-size:10.5pt; text-decoration:underline">《隐私政策》</span>
            </a>
            <!-- <span style="-aw-comment-end:_cmntref1"></span> -->
            <!-- <a href="#_cmnt1">[XXZ1]</a> -->
            <span style="font-family:宋体; font-size:10.5pt">基础上，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">本《儿童个人信息保护规则》（简称“本规则”）旨在帮助您作为儿童监护人全面了解我们如何收集、使用、披露、处理和存储您提供给我们的儿童个人信息，特别是以粗体</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">/粗体下划线标识的条款应重点阅读，在确认充分理解并同意全部条款后再允许儿童继续使用相关产品或服务</span>
            <span style="font-family:宋体; font-size:10.5pt">。我们希望与您一起共同保护儿童的个人信息，并承诺在未经您同意的情况下，不向任何网络产品和服务提供者提供任何儿童个人信息(但本规则特别约定或法律规定的除外)。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">本规则所称儿童是指不满十四周岁的未成年人。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">如果您是儿童，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">您需要和您的监护人一起仔细阅读本规则，并在征得您的监护人同意后，使用我们的产品、服务或向我们提供信息。 </span>
            <span style="font-family:宋体; font-size:10.5pt">  </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">除另有约定外，本规则所用术语和缩略词与联想《隐私政策》中的术语和缩略词具有相同的涵义。本规则包含以下内容：</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt 21pt; text-align:justify; text-indent:-21pt">
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">一、</span>
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">我们收集哪些信息以及如何使用信息</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt 21pt; text-align:justify; text-indent:-21pt">
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">二、</span>
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">对儿童个人信息的存储和保留</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt 21pt; text-align:justify; text-indent:-21pt">
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">三、</span>
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">您如何管理儿童的个人信息</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt 21pt; text-align:justify; text-indent:-21pt">
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">四、</span>
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">委托处理、转让、公开、共享儿童个人信息</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt 21pt; text-align:justify; text-indent:-21pt">
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">五、</span>
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">我们如何保护儿童个人信息</span>
        </p>
        <p style="margin:7.8pt 0pt 0pt 21pt; text-align:justify; text-indent:-21pt">
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">六、</span>
            <span style="color:#333333; font-family:宋体; font-size:10.5pt">联系我们 </span>
        </p>
        <h1 style="font-size:10.5pt; line-height:241%; margin:7.8pt 0pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc90458089">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">一、我们收集哪些信息以及如何使用信息</span>
            </a>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold"> </span>
        </h1>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">    </span>
            <span style="font-family:宋体; font-size:10.5pt">在您使用《联想教学教研》产品和服务过程中，为了确保您能及时了解未成年人的学习情况，进行顺畅的家校沟通，我们需要教师提供未成年人的【姓名、昵称、头像、课堂表现、提交的任务、打卡信息】，这些信息将由学校教师收集与上传到系统，我们不会主动收集任何未成年人的个人信息。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">针对我们专门面向十四</span>
            <span style="font-family:宋体; font-size:10.5pt">周岁以下儿童的产品和</span>
            <span style="font-family:宋体; font-size:10.5pt">/</span>
            <span style="font-family:宋体; font-size:10.5pt">或服务</span>
            <span style="font-family:宋体; font-size:10.5pt">，或其</span>
            <span style="font-family:宋体; font-size:10.5pt">部分功能，</span>
            <span style="font-family:宋体; font-size:10.5pt">在</span>
            <span style="font-family:宋体; font-size:10.5pt">注册或使用这类产品或服务</span>
            <span style="font-family:宋体; font-size:10.5pt">时，您可能</span>
            <span style="font-family:宋体; font-size:10.5pt">需要输入生日、年龄等</span>
            <span style="font-family:宋体; font-size:10.5pt">能够</span>
            <span style="font-family:宋体; font-size:10.5pt">识别用户年龄的</span>
            <span style="font-family:宋体; font-size:10.5pt">信息</span>
            <span style="font-family:宋体; font-size:10.5pt">，并按照法律法规的规定，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">在获得监护人同意之后才允许儿童继续使用相关产品或服务。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">当我们根据您提供的上述信息识别出用户为十四</span>
            <span style="font-family:宋体; font-size:10.5pt">周岁以下的儿童时，</span>
            <span style="font-family:宋体; font-size:10.5pt">做为儿童的监护人，您可能需要向我们提供</span>
            <span style="font-family:宋体; font-size:10.5pt">手机号码、电子邮箱</span>
            <span style="font-family:宋体; font-size:10.5pt">等信息，以便我们</span>
            <span style="font-family:宋体; font-size:10.5pt">验证您与您监护的儿童的监护关系</span>
            <span style="font-family:宋体; font-size:10.5pt">；</span>
            <span style="font-family:宋体; font-size:10.5pt">我们也可能会将您的</span>
            <span style="font-family:宋体; font-size:10.5pt">信息</span>
            <span style="font-family:宋体; font-size:10.5pt">与儿童帐号进行关联，以便</span>
            <span style="font-family:宋体; font-size:10.5pt">您管理儿童使用产品和/或服务的情况，并</span>
            <span style="font-family:宋体; font-size:10.5pt">保护您和您监护的儿童的</span>
            <span style="font-family:宋体; font-size:10.5pt">相关</span>
            <span style="font-family:宋体; font-size:10.5pt">权益</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">当儿童使用某款产品和/或服务时，或享受某些针对学生的特惠价格时，我们可能会收集其他相关的儿童个人信息，但我们会根据合法、正当、必要、诚信的原则，仅为实现前述目的收集</span>
            <span style="font-family:宋体; font-size:10.5pt">必要的信息。</span>
        </p>
        <p style="font-size:10.5pt; line-height:150%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">如果超出上述收集的您或您监护的儿童个人信息范围或目的的，我们将再次告知您，并征得您的同意。</span>
        </p>
        <p style="font-size:10.5pt; line-height:150%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">收集个人信息的目的在于向您监护的儿童提供产品和</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">/或服务，并且保证我们遵守</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">可</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">适用的法律、法规及</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">相关</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">规范性文件。您有权选择是否提供该</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">个人</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">信息，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">但多数情况下，如果您不提供，我们可能无法向您</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">监护的</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">儿童提供相应的服务，也无法回应</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">用户在使用相关产品和/或服务时</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">遇到的问题</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">为保护儿童的个人信息安全，我们强烈建议由您来完成提供孩子个人信息的行为。</span>
        </p>
        <h1 style="font-size:10.5pt; line-height:241%; margin:7.8pt 0pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc90458090">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">二、对儿童个人信息的存储和保留</span>
            </a>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold"> </span>
        </h1>
        <p style="font-size:10.5pt; line-height:125%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们采取了严格的加密措施来存储您监护的儿童的个人信息，以确保信息安全。我们严格设定了此类信息访问权限，来控制您监护的儿童个人信息的知悉范围。同时，我们也具备完备的技术能力，以避免违法复制、下载儿童个人信息。</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们会遵守所适用的相关法律的要求，或者基于本规则中所述的信息收集的目的所必需的期间来存储和保留个人信息。个人信息在完成收集目的，或在我们确认您的删除或注销申请后，或我们终止运营相应产品或服务后，我们将停止保留个人信息，并对所收集的儿童个人信息做删除或匿名化处理。如果是出于公众利益、科学、历史研究或统计的目的或其他法定目的，我们将基于适用的法律继续保留相关数据。</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">我们在中华人民共和国境内运营中收集和产生的儿童个人信息，将存储在中国境内</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
        </p>
        <h1 style="font-size:10.5pt; line-height:241%; margin:7.8pt 0pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc90458091">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">三、您如何管理儿童的个人信息</span>
            </a>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold"> </span>
        </h1>
        <p style="font-size:10.5pt; line-height:125%; margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（一）查询及更正信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">作为儿童监护人，如果您希望了解我们对儿童个人信息处理情况，或者如果您发现我们收集、存储、使用、披露的儿童个人信息有错漏的，您可以通过发邮件到第六条列示的电子邮箱的方式来联系我们。</span>
            <span style="font-family:宋体; font-size:10.5pt">一旦我们获得充分信息确认可处理您的请求时，我们将在</span>
            <span style="font-family:宋体; font-size:10.5pt">所</span>
            <span style="font-family:宋体; font-size:10.5pt">适用</span>
            <span style="font-family:宋体; font-size:10.5pt">的</span>
            <span style="font-family:宋体; font-size:10.5pt">法律规定的时间内对您的请求做出回应。 </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（二）撤回同意</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">您可以发送邮件到</span>
            <span style="font-family:宋体; font-size:10.5pt">第六条列示的电子邮箱，来提交请求撤销同意，包括收集、使用和</span>
            <span style="font-family:宋体; font-size:10.5pt">/或披露我们掌握或控制的您监护的儿童的个人信息。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您监护的儿童的个人信息。 </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">请注意，根据您授权我们处理信息的范围，您撤销同意可能导致您监护的儿童不能享受我们的产品或服务。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（三）注销服务或账号</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">如您希望注销儿童用户在联想申请的账号，您可以通过发送邮件至第六条列示的电子邮箱来提出申请。但提请您特别注意：</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">由于注销该账号的操作将使您无法使用本产品的功能和服务，请您谨慎操作。</span>
            <span style="font-family:宋体; font-size:10.5pt">我们为了保护您或他人的合法权益会结合您对联想各产品和服务的使用情况判断是否支持您的注销请求。</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（四）</span>
            <span style="font-family:宋体; font-size:10.5pt">如您发现我们存在如下情况时，您可以要求我们删除</span>
            <span style="font-family:宋体; font-size:10.5pt">儿童个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt">：</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">处理目的已实现、无法实现或者为实现处理目的不再必要；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">我们停止提供产品/服务，或者保存期限已届满；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">您撤回同意；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">我们违反法律、行政法规或者违反本指引约定处理个人信息；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">法律、行政法规规定的其他情形。</span>
            </li>
        </ol>
        <h1 style="font-size:10.5pt; line-height:241%; margin:7.8pt 0pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <a name="_Toc90458092">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">四、</span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">委托处理、转让、公开</span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">、共享儿童</span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">个人信息</span>
            </a>
        </h1>
        <p style="font-size:10.5pt; line-height:125%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们将遵照法律法规的规定，严格限制共享、转移、披露儿童个人信息的情形。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(一)</span>
            <span style="font-family:宋体; font-size:10.5pt">委托处理</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">仅为实现外部处理的目的，我们可能会委托第三方合作伙伴（包括服务供应商、承包商、代理、应用开发者等）处理儿童的个人信息；这种情形下，我们会对受委托方及委托行为等进行安全评估，签署委托协议，要求受委托方的处理行为不得超出我们的授权范围及限定的用途，并采用加密、匿名化、去标识化处理等手段保障您和儿童的信息安全。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(二)</span>
            <span style="font-family:宋体; font-size:10.5pt">转让</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">随着我们业务的持续发展，当发生我们以及我们的关联企业进行合并、收购、资产转让等类似交易，而导致向第三方转让您和儿童的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，并会要求新的持有您或儿童个人信息的实体继续受本规则的约束。如果本规则中约定的个人信息的处理方式发生任何改变，我们将要求该实体重新取得您的同意。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(三)</span>
            <span style="font-family:宋体; font-size:10.5pt">公开</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">除非取得您的有效同意或法律、行政法规规定应当披露的情形，我们不会披露儿童个人信息。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（四）共享及提供</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">一般情况下，我们不会向第三方共享及提供儿童的个人信息。我们仅在监护人事先同意或具备其他合法性基础的前提下，包括但不限于对第三方处理儿童个人信息的安全性进行评估等，才可能与第三方共享儿童的个人信息。</span>
        </p>
        <h1 style="font-size:10.5pt; line-height:241%; margin:7.8pt 0pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">五、我们如何保护儿童个人信息</span>
        </h1>
        <p style="font-size:10.5pt; line-height:125%; margin:0pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(一)</span>
            <span style="font-family:宋体; font-size:10.5pt">为保障儿童的信息安全，我们会在现有技术水平下努力采取合理必要的物理、技术和管理方面的安全措施来保护儿童的信息，以防止儿童的信息遭受违法复制、丢失、误用、非授权访问、下载、披露和更改</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(二)</span>
            <span style="font-family:宋体; font-size:10.5pt">我们建立</span>
            <span style="font-family:宋体; font-size:10.5pt">了</span>
            <span style="font-family:宋体; font-size:10.5pt">专门</span>
            <span style="font-family:宋体; font-size:10.5pt">的组织、</span>
            <span style="font-family:宋体; font-size:10.5pt">管理制度、流程</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">并采取相应措施确保信息安全。我们将</span>
            <span style="font-family:宋体; font-size:10.5pt">遵循严格的</span>
            <span style="font-family:宋体; font-size:10.5pt">授权</span>
            <span style="font-family:宋体; font-size:10.5pt">和审批</span>
            <span style="font-family:宋体; font-size:10.5pt">原则，设定信息访问权限，控制</span>
            <span style="font-family:宋体; font-size:10.5pt">工作人员对</span>
            <span style="font-family:宋体; font-size:10.5pt">儿童个人信息的</span>
            <span style="font-family:宋体; font-size:10.5pt">访问</span>
            <span style="font-family:宋体; font-size:10.5pt">范围</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">并对访问情况进行记录。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(三)</span>
            <span style="font-family:宋体; font-size:10.5pt">若我们确认发生个人信息泄露等安全事件，我们会立即启动应急预案，采取补救措施，阻止安全事件扩大，如涉及儿童的个人信息，我们会将事件相关情况以邮件、信函、电话、推送通知等方式向您告知；如难以逐一告知，我们会采取公告的形式发布相关警示信息。同时，我们还将按照监管部门要求上报个人信息安全事件的处置情况。</span>
        </p>
        <h1 style="font-size:10.5pt; line-height:241%; margin:7.8pt 0pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">六、联系我们</span>
            <span style="-aw-bookmark-end:_Toc90458092"></span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold"> </span>
        </h1>
        <p style="font-size:10.5pt; line-height:125%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">如果您对本规则有任何意见，或者您对我们收集、使用或披露您监护的儿童的个人信息有投诉、举报或其他问题，请通过下方地址联系我们。</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">联系地址：北京市海淀区西北旺东路</span>
            <span style="font-family:宋体; font-size:10.5pt">10号院联想总部西区 法务部 100094</span>
            <span style="font-family:宋体; font-size:10.5pt">；</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">邮箱：</span>
            <span style="font-family:'Times New Roman'; font-size:10.5pt">privacy@lenovo.com</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="font-size:10.5pt; line-height:125%; margin:7.8pt 0pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">本规则或根据联想产品和/或服务专门制定的《儿童个人信息保护声明》等类似文件，为</span>
            <a name="_cmntref2">
                <span style="font-family:宋体; font-size:10.5pt">联想</span>
            </a>
            <!-- <a style="color:#800080" href="https://educenter.lenovo.com/Account/LenovoAbout"> -->
            <a style="color:#800080" :href="privacyUrl">
                <span style="color:#800080; font-family:宋体; font-size:10.5pt; text-decoration:underline">《隐私政策》</span>
            </a>
            <!-- <span style="-aw-comment-end:_cmntref2"></span>
            <a href="#_cmnt2">[XXZ2]</a> -->
            <span style="font-family:宋体; font-size:10.5pt">的一部分。如在儿童个人信息保护方面有与</span>
            <a name="_cmntref3">
                <span style="font-family:宋体; font-size:10.5pt">联想</span>
            </a>
            <!-- <a style="color:#800080" href="https://educenter.lenovo.com/Account/LenovoAbout"> -->
            <a style="color:#800080" :href="privacyUrl">
                <span style="color:#800080; font-family:宋体; font-size:10.5pt; text-decoration:underline">《隐私政策》</span>
            </a>
            <!-- <span style="-aw-comment-end:_cmntref3"></span>
            <a href="#_cmnt3">[XXZ3]</a> -->
            <span style="font-family:宋体; font-size:10.5pt">有不一致的，以本规则或特别制定的声明为准。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">请您理解，如本规则或特别制定的保护声明未规定之处，将参照适用联想所提供产品或服务的</span>
            <!-- <a href="https://educenter.lenovo.com/Account/LenovoAbout"> -->
            <a :href="privacyUrl">
                <span style="-aw-comment-start:_cmntref4"></span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">《隐私政策》</span>
            </a>
            <!-- <a name="_cmntref4"></a>
            <span style="-aw-comment-end:_cmntref4"></span>
            <a href="#_cmnt4">[XXZ4]</a> -->
            <span style="font-family:宋体; font-size:10.5pt">。</span>
        </p>
    </div>
</div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
    privacyUrl(){
        if(location.hostname == "educenter-lenovo.forclass.net"){
            return location.protocol + "//educenter-lenovo.forclass.net/Account/LenovoAbout"
        }else if(location.hostname == "educenter.lenovo.com"){
            return location.protocol + "//educenter.lenovo.com/Account/LenovoAbout"
        }
    }
  },
  watch: {
  },
  methods: {
  },
  created() {
    
  },
};
</script>
<style lang="scss">

</style>
