<template>
<div>
<div>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:18pt; font-weight:bold">联想许可协议和服务条款</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">重要提醒━请您仔细阅读本文件！</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">====================</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">您使用本文件项下联想设备及相关软件（“软件”）及其他服务（“服务”），受本联想许可和服务条款（以下称“本协议”）以及本协议援引的其他法律条款的约束。</span>
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">在下载、安装和使用软件和服务前，请务必仔细阅读本协议条款。</span>
        <span style="font-family:宋体; font-size:10.5pt">“您”是指任何个人或单一法律实体。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">1.0本协议的结构</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">本协议条款是通用条款，适用于本协议项下的所有软件和服务。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">除通用条款外，我们可能会针对特定软件或服务提供特别条款，则该特别条款将成为本协议的组成部分。如特别条款与通用条款不一致，则仅就该特定的软件或服务而言，以特别条款为准。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">某些软件或服务可能由第三方提供，就该第三方软件或服务的使用，受该第三方法律条款的约束。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">2.0接受本协议</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">2.1您必须首先接受和同意本协议方可使用软件和服务。</span>
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">如您不同意本协议条款，请不要安装、下载或使用软件及服务。如您为购买软件或服务支付了费用，您可</span>
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">联系您</span>
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">的供货商以获得退款。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">2.2您可通过以下方式接受本协议：（1）按任何软件或服务用户界面的提示，选择接受或同意本协议；（2）实际使用本协议项</span>
        <span style="font-family:宋体; font-size:10.5pt">下软件</span>
        <span style="font-family:宋体; font-size:10.5pt">或服务。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">2.3</span>
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">您应确保您已年满18岁、是独立生活的未成年人或获得了父母或监护人的同意，并具有达成有法律约束力协议的完全行为能力。</span>
        <span style="font-family:宋体; font-size:10.5pt">如果您未达到</span>
        <span style="font-family:宋体; font-size:10.5pt">可以达成有法律约束力合同的法定成人年龄，您应确保本协议是由您的父母或监护人</span>
        <span style="font-family:宋体; font-size:10.5pt">代表您签署</span>
        <span style="font-family:宋体; font-size:10.5pt">确认的。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">如您代表他人（例如某个公司或您的客户）确认接受本协议，您声明并保证您已经获得了充分的授权并有资格这样做。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">3.0软件许可</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">3.1本协议项</span>
        <span style="font-family:宋体; font-size:10.5pt">下软件</span>
        <span style="font-family:宋体; font-size:10.5pt">由联想或其供应商拥有并受版权保护，仅为许可使用而非销售给您。联想将授予您一项个人的、中国大陆地区范围的、免费的、不可转让的、非排他的和无分许可权的使用许可，使您可以本协议允许的方式使用软件。您可仅限于为支持该使用的目的制作和安装包括备份在内的副本。本协议适用于</span>
        <span style="font-family:宋体; font-size:10.5pt">您制作</span>
        <span style="font-family:宋体; font-size:10.5pt">的每一个副本。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">3.2</span>
        <span style="font-family:宋体; font-size:10.5pt">您不得</span>
        <span style="font-family:宋体; font-size:10.5pt">复制、修改、分发、销售或出租软件或其任何部分，不得对软件制作派生作品、进行反向工程、反汇编、反编译、提取源代码或实施其他解译行为，除非相关法律禁止此类限制或您另外获得了我们的书面允许。</span>
        <span style="font-family:宋体; font-size:10.5pt">您不得</span>
        <span style="font-family:宋体; font-size:10.5pt">删除或更改软件的任何版权和所有权信息。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">3.3您应确保使用软件的任何人都将遵守本协议。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">3.4本协议项下的某些软件或其组件可能基于随软件附带的开源许可证提供。开源许可证中的某些条款可能会取代本协议的某些条款。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">4.0联想账户注册</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">4.1 某些情况下，为使用软件或服务，您可能需要依照联想账户注册流程创立一个您个人的账户 (“账户”) 。某些软件或服务允许</span>
        <span style="font-family:宋体; font-size:10.5pt">您注册</span>
        <span style="font-family:宋体; font-size:10.5pt">和使用联想或第三方账户（例如,联想通行证），此时您会受适用于该联想或第三方账户的法律条款的约束。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">4.2在您的账户注册过程中以及在此后联想要求时，您同意向联想提供真实、准确、最新和完整的必要账户管理信息和数据（如您的姓名、电子邮件地址及电话号码等，统称为“注册信息”）。您应始终维护并随时及时</span>
        <span style="font-family:宋体; font-size:10.5pt">更新您</span>
        <span style="font-family:宋体; font-size:10.5pt">的注册信息。如果您提供的注册信息虚假、不正确、不完整或已经过时，可能会影响您对软件或服务的使用。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">5.0账户和密码安全</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">5.1您应自行负责妥善</span>
        <span style="font-family:宋体; font-size:10.5pt">保管您</span>
        <span style="font-family:宋体; font-size:10.5pt">的登录信息和密码。无论是否经过您的授权，对通过您账户进行的或在您账户中发生的所有活动，均应由您自行负责。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">5.2如发现对您账户的任何未经授权的使用或发生其他此类安全问题，您应立即告知我们。对他人未经授权使用您的</span>
        <span style="font-family:宋体; font-size:10.5pt">帐户</span>
        <span style="font-family:宋体; font-size:10.5pt">，联想不承担任何责任。 </span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">5.3 账户由确认和接受本协议的人拥有。如此后账户所有权发生变化，应有相关法律文件证明。联想不对与账户内部活动及账户所有权相关的争议进行判定。如不能确定账户的有效所有者，联想有权暂停或取消该账户。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">6.0 使用政策</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">6.1在使用本协议项</span>
        <span style="font-family:宋体; font-size:10.5pt">下软件</span>
        <span style="font-family:宋体; font-size:10.5pt">或服务时，您应遵守我们告知的所有可适用的使用政策。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">6.2</span>
        <span style="font-family:宋体; font-size:10.5pt">您不得</span>
        <span style="font-family:宋体; font-size:10.5pt">为任何非法、欺诈、不当或滥用的目的或以任何可能妨碍其他用户使用软件或服务，或损害联想或其他用户的任何财产，以及侵犯或妨害第三方权利的方式使用软件或服务。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">6.3您对本协议项下服务的使用应遵循所有相关的国家、地方、国际和国内法律的规定。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">6.4对因您使用本软件或服务以及您违反使用政策导致的损失、损害及其他后果，由您自行负责。您同意为联想及其供应商提供补偿、抗辩并确保其不因您的数据、您对软件或服务的使用或您违反本协议条款所导致的任何损失、成本、责任及其他费用而受到损害。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">7.0用户数据的存储</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">7.1某些服务允许您上传、存储、发送或接受某些您的数据，其中可能包含您的个人数据（“用户数据”）。您对这些用户数据所拥有的任何权利均由您保留，</span>
        <span style="font-family:宋体; font-size:10.5pt">除非经您的</span>
        <span style="font-family:宋体; font-size:10.5pt">事先同意和允许，否则联想不会自己或允许他人使用您的用户数据。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">7.2您通过我们的服务上传、存储、传送或接收用户数据，视为您授予联想及其服务提供商一个全球范围的许可，使其可以作为服务的组成部分并仅为提供和改进服务的目的，复制并以加密方式</span>
        <span style="font-family:宋体; font-size:10.5pt">存储您</span>
        <span style="font-family:宋体; font-size:10.5pt">的数据。您应确保您拥有所有必要的权利和权力授予上述许可。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">7.3为您的用户数据提供存储不是联想的义务，我们仅为用户方便的目的提供此项服务，为此，您知道并同意，联想将不对数据无论因任何原因被删除或存储失败承担责任。</span>
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">您应自行负责及时对您的用户数据做妥善的备份。</span>
        <span style="font-family:宋体; font-size:10.5pt">您知道并同意，我们可能会对您上传或存储的用户数据的数量和期限设置限制。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">8.0数据收集、使用及隐私权保护</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">8.1为提升您的使用体验及改善我们的产品和服务，我们可能会收集您对软件和服务使用情况的一些基本信息，包括您安装了联想软件的设备类型等。当您连接到互联网时，这些信息会发送到联想，这个过程可能会消耗微小的互联网流量，但不会对您的设备使用带来任何不便。这个过程不会收集任何可用于</span>
        <span style="font-family:宋体; font-size:10.5pt">识别您</span>
        <span style="font-family:宋体; font-size:10.5pt">个人身份的信息。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">8.2 您同意联想可以为履行本协议的目的，通过电子邮件或</span>
        <span style="font-family:宋体; font-size:10.5pt">消息弹窗等</span>
        <span style="font-family:宋体; font-size:10.5pt">方式向您发送一些公告、管理信息以及其他与软件或服务有关的技术服务和支持信息。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">8.3在注册联想账户或登录服务时，您可能会被要求提供注册信息，其中可能包含您的个人身份信息。您同意联想可以根据其隐私权政策，自行或允许其服务提供商为提供和改进软件或服务的目的而使用这些信息。您同意联想可以在符合相关法律规定的前提下，在您所在国家或地区之外存储和传输上述数据和信息。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">您可随时在设备或软件的设置中关闭使用情况统计及信息通知功能，以停止我们对您使用情况信息的数据收集以及向您发送服务信息。您可阅读适用于联想软件和网站的联想隐私权政策声明,了解我们收集、使用、存储和处理您的注册信息及用户数据相关政策的详情。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">9.0软件和服务的更新</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">9.1一旦本软件或服务有新的版本或特征，</span>
        <span style="font-family:宋体; font-size:10.5pt">您设备</span>
        <span style="font-family:宋体; font-size:10.5pt">上的软件将被自动更新。您可通过调整设置停止软件或服务的自动更新。本协议条款适用于软件和服务的所有更新和补丁程序。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">9.2您可随时停止使用我们的软件和服务。我们也可能会不时变更或改进我们的软件和服务，对某些软件和服务变更我们可能不会事先通知。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">9.3联想保留经提前</span>
        <span style="font-family:宋体; font-size:10.5pt">一</span>
        <span style="font-family:宋体; font-size:10.5pt">（1）</span>
        <span style="font-family:宋体; font-size:10.5pt">个</span>
        <span style="font-family:宋体; font-size:10.5pt">月通知，随时中止或永久停止提供某项服务或其任何部分的权利。在上述期限内，联想将</span>
        <span style="font-family:宋体; font-size:10.5pt">尽商业</span>
        <span style="font-family:宋体; font-size:10.5pt">上合理的努力，使您能够下载可能存于我们服务中的数据。过了该期限之后，您的数据将被安全销毁。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">10.0服务费用</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">使用某些服务可能需要您另外支付费用，具体按相关服务条款执行。我们保留对服务收取费用及改变收费标准和收费方式的权利。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">除以信用卡、借记卡或电子支付方式进行的交易外，费用应在收到发票时到期应付，您同意支付发票上指明的费用，包括任何可适用的税款和滞纳金。您自行负责支付与服务相关的税款。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">11.0无保证声明</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">11.1除非联想另有明确规定，否则软件和服务均以“按现状”且“可用”为基础提供，无任何保证。 在法律允许的最大限度内，我们明确否认任何包括但不限于有关适销性、适用于某个特定目的、权属以及</span>
        <span style="font-family:宋体; font-size:10.5pt">不</span>
        <span style="font-family:宋体; font-size:10.5pt">侵权在内的明示、默示及法定的保证以及任何有关安全、可靠性、及时性和软件及服务性能的保证。以上排除和限制同时适用于任何联想的供应商及分销商。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">11.2某些国家或司法管辖区不允许排除或限制某些默示保证，此时以上排除和限制将不适用于您。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">12.0责任范围</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">12.1如联想违约或有其他责任，您有权要求联想赔偿损失。在上述情形下，无论您基于何种理由要求联想赔偿损失（包括重大违约、过失、虚假陈述或其他合同或侵权方面的索赔），除根据可适用法律不能免除或限制的责任外，且仅在根据该法律不能免除或限制的范围内，联想的赔偿责任仅限于您遭受的实际直接损失，且最高不超过您为软件或服务所支付的价款。此限制不适用于联想应依法承担的人身伤害（包括死亡）、不动产和有形动产的损害赔偿责任。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">此限制同样适用于联想的供应商和销售商。这是联想及其供应商和销售商所承担责任总和的上限。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">12.2 在任何情况下，联想及其供应商或销售商对下列情形均不承担责任，即使已被告知该情形有可能发生：（1）第三方向您提出的索赔；（2）您的数据丢失或损坏；或（3）特殊的、附带的或间接的损害赔偿或任何后果性的经济损害赔偿，包括利润、业务收入、商誉或预期节余的损失。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">12.3 某些国家/地区或司法管辖区不允许对附带的或后果性的损害赔偿进行排除或限制，如果这样，则上述排除或限制可不适用于您。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">12.4 本协议项</span>
        <span style="font-family:宋体; font-size:10.5pt">下软件</span>
        <span style="font-family:宋体; font-size:10.5pt">和服务不适用于任何与原子能、航空、公共交通运输、医疗或任何其他具有内在危险性的应用有关的用途。此类应用可能会导致死亡、人身损害、事故或大规模的破坏。您同意，对将软件或服务用于此类用途导致的后果，我们将不承担任何性质的责任。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">13.0消费者权利</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">本协议的任何规定均不影响不能通过合同予以放弃或限制的消费者的任何法定权利。根据相关法律规定，您可能还拥有不能通过本协议改变的其他权利。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">14.0知识产权</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">您知道并同意，联想或第三方拥有本软件或服务，或其任何部分，或通过或随同本软件或服务提供或获得的任何内容中含有的或与之有关的知识产权、所有权和权益。除非另有明确的许可，否则本软件和服务中含有的或与之相关的所有权利均由联想或第三方保留，联想未授予</span>
        <span style="font-family:宋体; font-size:10.5pt">您任何</span>
        <span style="font-family:宋体; font-size:10.5pt">默示的许可。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">我们将会根据著作权相关法律规定的流程，对著作权侵权通知进行处理，并采取措施屏蔽那些反复实施侵权行为的账户。如您认为某些人侵犯了您的著作权，您可按照联想版权政策声明的规定通知联想。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">15.营销材料和推广服务</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">我们可能会不时向您发送一些营销材料以及为您提供附加的推广服务，而不会就此另行向您收取费用（“推广服务”）。您知道并同意，我们可以通过电子传输、电子邮件、信函或其他方式向您发送上述营销和推广材料。您可随时通知我们停止发送上述材料。您了解并同意我们可能会不经另行通知随时调整我们提供上述推广服务的范围，某些推广服务可能仅针对新客户，您可能并不能获得某些或所有上述推广服务。如果你获得了我们提供的任何推广或特别价格或条件，这些价格或条件仅适用于您，您应对这些价格和条件保密，未经我们事先书面明确同意，</span>
        <span style="font-family:宋体; font-size:10.5pt">您不得</span>
        <span style="font-family:宋体; font-size:10.5pt">将此类信息披露给任何第三方。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">16.期限、终止和变更</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">16.1本协议在您接受后生效直至根据本协议规定终止。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">16.2您可以通过停止使用本软件或服务，或关闭您的账户的方式随时终止本协议。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">16.3如发现您正在为任何未经允许的或本协议禁止的用途使用软件或服务，联想可根据其判断终止本协议并就服务使用费及因您不当使用导致的损失向您索赔。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">16.4您同意，本协议终止后，您使用软件和服务的权利将立即终止。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">16.5 本协议标题为“用户数据存储”、“知识产权”、“无保证声明”、“责任限制”及“其他”的条款在本协议到期或终止后继续有效。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">16.6我们保留不时修改本协议（包括通用条款和特别条款）的权利，以反映相关法律及我们服务的变化，这些修改不会给您带来不利影响。本协议经修改后，将在新用户接受后对新用户生效，对于原有用户，修订后的协议将在我们通知您后第十五（15）天起生效。但是，针对服务新功能的或基于法律原因所做的修改将立即生效。如果您不同意针对某项服务的修改后的条款，应停止使用该服务。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">17.0第三方软件和服务</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">17.1您了解并同意某些软件或服务可能由第三方根据其不同于本协议的独立条款提供。联想对第三方软件或服务不提供任何保证，第三方可能会就其软件或服务提供自己的保证。 您理解并同意，您将基于自己的判断并自行承担风险通过本软件或服务下载或随同本软件或服务使用任何第三方软件或服务，并且，对因下载或使用第三方软件或服务所导致的任何责任，将依法律规定以及您与该第三方的法律条款承担，与联想无关。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">17.2联想软件或服务用户界面上可能提供对第三方网站的链接或引用，您点击这些链接会进入非由联想运营、管理和支持的第三方网站。这些链接或引用仅为用户方便而设置，并不表明我们对这些网站及其内容</span>
        <span style="font-family:宋体; font-size:10.5pt">作出</span>
        <span style="font-family:宋体; font-size:10.5pt">了任何认可、推荐或保证。对这些第三方网站的服务及其内容，联想不做任何陈述和保证，包括但不限于其可获得性和持续性。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">17.3您对第三方网站的使用可能受制于特定的法律条款并可能有许可及服务费产生。第三方网站的服务条款和隐私权政策可能与联想的规定有差异。您向这些第三方网站提交的或由其收集的您的个人信息，不适用联想的隐私权政策。如您选择使用该第三方网站，请您仔细阅读该第三方的相关服务条款和隐私权政策（如果有的话）。在进入或使用该第三方网站时，请您自行确定是否采取必要的防范措施，以免遭受病毒、蠕虫、木马和其他有害内容的侵害，联想不对这些网站的内容以及您对该网站的使用所导致的损害承担任何责任。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">18.0一般条款</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">18.1本协议依据购买地司法管辖区的法律制订和解释，且不考虑其冲突法规则。联合国国际货物销售合同公约不适用于本协议。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">18.2联想未行使或强制执行包含在本协议中的任何权利或条款，</span>
        <span style="font-family:宋体; font-size:10.5pt">不</span>
        <span style="font-family:宋体; font-size:10.5pt">视为对相关权利和条款的放弃。如本协议的任何规定或部分被有管辖权的裁判机构判定为不可强制执行，不影响本协议的其他部分的效力，本协议其他部分仍继续有效。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">18.3 本协议结合联想隐私权政策构成您与联想之间就本协议标的达成的全部协议，并取代您与联想</span>
        <span style="font-family:宋体; font-size:10.5pt">此前或</span>
        <span style="font-family:宋体; font-size:10.5pt">同时就本协议标的达成的任何其他谅解或协议，无论是口头的还是书面的。任何对本协议任何条款的弃权只能以书面方式为之并经联想签署后方为有效。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">18.4因本协议发生的任何争议，均应提交购买地司法管辖区的裁判机构裁决。但上述约定并不禁止联想向任何有管辖权的法院申请禁制令或其他保护其知识产权的强制措施。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">18.5未经联想事先书面同意，你不得向第三方分派或</span>
        <span style="font-family:宋体; font-size:10.5pt">转让您</span>
        <span style="font-family:宋体; font-size:10.5pt">在本协议项下的任何权利。任何违反上述规定的分派或转让均属无效。联想可根据其业务模式安排关联机构或分包</span>
        <span style="font-family:宋体; font-size:10.5pt">商执行</span>
        <span style="font-family:宋体; font-size:10.5pt">本协议项下的部分或全部业务。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:10.5pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:9pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:9pt; font-weight:bold">联想、Lenovo文字及徽标是联想集团所属企业的商标或注册商标。提及的其他商号、产品和服务名称可能是其他公司的</span>
        <span style="font-family:宋体; font-size:9pt; font-weight:bold">的</span>
        <span style="font-family:宋体; font-size:9pt; font-weight:bold">商标。版权所有 © 2017 联想集团所属企业。保留所有权利。</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:9pt; font-weight:bold">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:9pt; font-weight:bold">联想（北京）有限公司</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:9pt; font-weight:bold">北京市海淀区西北旺东路10号院1号楼联想总部东区</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:11pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; text-align:justify">
        <span style="font-family:宋体; font-size:11pt">&nbsp;</span>
    </p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:Calibri; font-size:10.5pt">&nbsp;</span>
    </p>
</div>
</div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
    
  },
  watch: {
  },
  methods: {
  },
  created() {
    
  },
};
</script>
<style lang="scss">

</style>
