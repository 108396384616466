<template>
<div>
    <div>
        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
            <span style="font-family:宋体; font-size:12pt; font-weight:bold">隐私政策</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:right; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">生效日期：</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">【</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">2022】年【6】月【30】日</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:right; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">本《</span>
            <span style="font-family:宋体; font-size:10.5pt">隐私政策》（</span>
            <span style="font-family:宋体; font-size:10.5pt">以下</span>
            <span style="font-family:宋体; font-size:10.5pt">称</span>
            <span style="font-family:宋体; font-size:10.5pt">“本</span>
            <span style="font-family:宋体; font-size:10.5pt">政策</span>
            <span style="font-family:宋体; font-size:10.5pt">”</span>
            <span style="font-family:宋体; font-size:10.5pt">）</span>
            <span style="font-family:宋体; font-size:10.5pt">适用于由</span>
            <span style="font-family:宋体; font-size:10.5pt">【</span>
            <span style="font-family:宋体; font-size:10.5pt">联想（北京）有限公司</span>
            <span style="font-family:宋体; font-size:10.5pt">】</span>
            <span style="font-family:宋体; font-size:10.5pt">（以下</span>
            <span style="font-family:宋体; font-size:10.5pt">称</span>
            <span style="font-family:宋体; font-size:10.5pt">“我们”或“联想”</span>
            <span style="font-family:宋体; font-size:10.5pt">）</span>
            <span style="font-family:宋体; font-size:10.5pt">为您</span>
            <span style="font-family:宋体; font-size:10.5pt">提供的</span>
            <span style="font-family:宋体; font-size:10.5pt">【联想教学教研</span>
            <span style="font-family:宋体; font-size:10.5pt">】</span>
            <span style="font-family:宋体; font-size:10.5pt">及</span>
            <span style="font-family:宋体; font-size:10.5pt">其服务</span>
            <span style="font-family:宋体; font-size:10.5pt">（以下通</span>
            <span style="font-family:宋体; font-size:10.5pt">称</span>
            <span style="font-family:宋体; font-size:10.5pt">“产品”</span>
            <span style="font-family:宋体; font-size:10.5pt">）</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">重视您的隐私</span>
            <span style="font-family:宋体; font-size:10.5pt">保护</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">并</span>
            <span style="font-family:宋体; font-size:10.5pt">深知个人信息对您的重要性</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">将</span>
            <span style="font-family:宋体; font-size:10.5pt">通过本政策向您说明该产品将会如何</span>
            <span style="font-family:宋体; font-size:10.5pt">收集</span>
            <span style="font-family:宋体; font-size:10.5pt">、使用和存储您的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt">、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">会</span>
            <span style="font-family:宋体; font-size:10.5pt">如何保护您的个人</span>
            <span style="font-family:宋体; font-size:10.5pt">信息</span>
            <span style="font-family:宋体; font-size:10.5pt">以</span>
            <span style="font-family:宋体; font-size:10.5pt">及</span>
            <span style="font-family:宋体; font-size:10.5pt">您享有何种权利。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">您清楚了解并确认：本产品仅适用于教学教研目的。您作为产品使用者或管理员，需要提供个人信息以通过身份验证。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">因使用本产品而涉及到由教师或学校收集或处理个人信息的，应由学校及教师按照可适用的法律法规、政策及行业标准收集和处理相关个人信息并承担责任。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">请</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">您在使用产品前，仔细阅读并了解本政策。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">重点</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">内容我们已经以粗体字</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">加</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">下划线</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">表示</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">，希望您特别关注</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">一旦</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">您开始</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">使用本产品，即表示</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">您同意我们按照本政策处理您的相关信息。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">本政策将帮助您了解以下内容：</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">一、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何收集和使用您的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">二、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何共享、转让、公开披露您的个人信息</span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">三、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">如何存储您的个人信息</span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">四、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何保护您的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">五、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何使用</span>
            <span style="font-family:宋体; font-size:10.5pt"> Cookie </span>
            <span style="font-family:宋体; font-size:10.5pt">和同类技术</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">六、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何处理未成年人的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">七、</span>
            <span style="font-family:宋体; font-size:10.5pt">您如何行使管理</span>
            <span style="font-family:宋体; font-size:10.5pt">个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt">的权利</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">八、</span>
            <span style="font-family:宋体; font-size:10.5pt">本政策如何更新</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 35.2pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">九、</span>
            <span style="font-family:宋体; font-size:10.5pt">如何联系我们</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">需要特别说明的是，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">本政策不适用于其他第三方向您提供的软件</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">、</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">产品</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">或</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">服务。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">一、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何收集和使用您的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">为实现本产品的基本业务功能，我们可能需要向您收集个人信息。以下将详细列出本产品的基本业务功能及为实现该功能所需收集的个人信息，若您拒绝收集，则无法使用本产品或服务。</span>
        </p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt">
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:53.25pt">
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">基本业务功能</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:423.25pt">
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">功能</span>
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">说明</span>
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">及需要收集的信息</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:53.25pt">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">用户账户注册和登录</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:423.25pt">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">由学校教师或是家长自行注册账号信息或管理员完成所管辖学校教师的账号建立和信息维护。注册完成后手机号将可作为登录账号进行使用。需要收集的信息：教师姓名</span>
                    </p>
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">您还可以通过微信授权方式进行登录，我们会申请获取您的微信头像、昵称、地区和性别信息。我们最终能够获得的个人信息将以微信授权的内容为准。若您拒绝提供微信账号下的信息，则您无法使用微信账号的方式注册并登录。</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:53.25pt">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">教研活动</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:423.25pt">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">教师参与教研活动时在线协同编辑，并将相关数据存储至联想服务器。</span>
                    </p>
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">需要提供的信息：教师在系统中协同编辑的相关内容（文档、音频、视频、图片及备注文字）</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">教师上传文档资源</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">教师上传成果资源，并存储至联想服务器</span>
                    </p>
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">需要提供的信息：教师在系统中上传的相关内容（文档、音频、视频、图片）</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">教学教研应用使用情况统计分析</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">为完成教师使用系统的数据分析功能，并对教师进行统计排名，联想将对相关数据进行收集并存储至联想与服务器</span>
                    </p>
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">所需要收集的信息：教师姓名</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">创建班级</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">为了方便教师实现班级管理功能，以及加强与家长的沟通，教师可以使用创建班级的功能。此功能的使用需要教师提供学生姓名、昵称、头像、家长姓名及账户名。教师在提供学生及家长的个人信息建立班级以及学生档案前，需要按照相关法律法规获得家长的同意，即将本文件及《联想教学教研许可及服务协议》告知学生家长，由学生家长完成本应用的注册和登录。</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">教师点评学生课堂表现与家长查看功能</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">为了实现“教师对班内学生进行点评”的功能，需要教师提供学生的姓名、课堂表现、提交的任务、打卡信息。为了实现“家长关联学生来查看学生的课堂表现”，需要教师提供学生的上课的表现情况。</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:center">
                        <span style="font-family:宋体; font-size:10.5pt">家校沟通</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top">
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">为了加强家长与学校教师之间的沟通与联动，我们提供了家校沟通功能。如果您是教师，您可以查看学生家长提交任务信息与打卡信息；如果您是学生家长，您可以查看教师布置任务通知信息与打卡信息。我们会收集您向我们主动提供的任务通知信息与打卡信息，包括相关文字、图片、音频、视频信息。</span>
                    </p>
                    <p style="margin:0pt; text-align:justify">
                        <span style="font-family:宋体; font-size:10.5pt">当您使用家校沟通功能时，我们需要获取您的外置存储读写权限、相机权限和麦克风权限。</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.25pt">
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">聊天功能</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:423.25pt">
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">为了方便教师与家长的顺畅沟通，及时了解学生的学习情况，您可以使用本软件的聊天功能，为此，您需要主动提供聊天记录信息，包括聊天文字与图片信息。</span>
                    </p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">当您使用聊天功能时，我们需要获得您的外置存储读写权限和相机权限。</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.25pt">
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">图片智能识别功能</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:423.25pt">
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">在教师创建班级时，我们为教师提供图片智能识别功能，教师可以使用本功能对学生花名册图片进行智能识别，快速录入学生的姓名。为此，您需要提供学生花名册图片和学生姓名信息。</span>
                    </p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">当您使用本功能时，我们需要获取您的外置存储读写权限和相机权限。</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.25pt">
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">在校表现</span>
                    </p>
                </td>
                <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:423.25pt">
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt">为了便于汇总分析学生获得点评的情况，我们会收集学生的姓名、课堂表现、提交的任务、打卡信息、教师点评信息。</span>
                    </p>
                </td>
            </tr>
        </table>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(一)</span>
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">从第三方</span>
            <span style="font-family:宋体; font-size:10.5pt">间接</span>
            <span style="font-family:宋体; font-size:10.5pt">获得您个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt">的</span>
            <span style="font-family:宋体; font-size:10.5pt">情形</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">可能从第三方获取您授权共享的账户信息</span>
            <span style="font-family:宋体; font-size:10.5pt">（昵称、</span>
            <span style="font-family:宋体; font-size:10.5pt">头像）</span>
            <span style="font-family:宋体; font-size:10.5pt">，使您可以</span>
            <span style="font-family:宋体; font-size:10.5pt">通过第三方账户直接</span>
            <span style="font-family:宋体; font-size:10.5pt">登录</span>
            <span style="font-family:宋体; font-size:10.5pt">并使用我们的产品或服务</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">为此我们需要您同意本政策并将您的第三方账户与本产品账户</span>
            <span style="font-family:宋体; font-size:10.5pt">进行</span>
            <span style="font-family:宋体; font-size:10.5pt">绑定。</span>
            <span style="font-family:宋体; font-size:10.5pt">我们仅会在您的授权同意的范围内收集并使用您的个人信息。 </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(二)</span>
            <span style="font-family:宋体; font-size:10.5pt">设备权限调用</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">为向您（教师）提供便捷、优质的服务，我们可能会调用您设备的一些权限，以下是我们可能调用的设备权限列表及对应的使用目的说明，您有权随时选择关闭下列权限的授权，但可能会影响您正常使用相关服务或产品的部分或全部功能。</span>
        </p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt; width:489.8pt">
            <thead>
                <tr style="height:18pt">
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:44.7pt">
                        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">设备权限</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:151.05pt">
                        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">调用权限的目的</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:65.35pt">
                        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">是否询问</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:37.65pt">
                        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">可否关闭权限</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:130.3pt">
                        <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">关闭权限</span>
                            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">对相应功能的影响</span>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style="height:18pt">
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:44.7pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">摄像头</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:151.05pt">
                        <ol type="1" style="margin:0pt; padding-left:0pt">
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">在您创建班级时，我们为您提供图片智能识别功能，您可以使用本功能对学生的花名册图片进行智能识别，快速录入学生的姓名。</span>
                            </li>
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">教师拍照或是上传本地照片至班级相册；</span>
                            </li>
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">教师发布通知</span>
                                <span style="font-family:Calibri; font-size:10.5pt">/</span>
                                <span style="font-family:宋体; font-size:10.5pt">打卡</span>
                                <span style="font-family:Calibri; font-size:10.5pt">/</span>
                                <span style="font-family:宋体; font-size:10.5pt">作业时附加拍照信息；</span>
                            </li>
                        </ol>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:65.35pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">否</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:37.65pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">是</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:130.3pt">
                        <ol type="1" style="margin:0pt; padding-left:0pt">
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">无法使用图片智能识别功能</span>
                            </li>
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">无法上传现拍照片至班级相册</span>
                            </li>
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">无法上传现拍照片至通知、打卡、作业模块</span>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style="height:18pt">
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:44.7pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">本地相册</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:151.05pt">
                        <ol type="1" style="margin:0pt; padding-left:0pt">
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">教师上传本地照片至班级相册；</span>
                            </li>
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">教师发布通知</span>
                                <span style="font-family:Calibri; font-size:10.5pt">/</span>
                                <span style="font-family:宋体; font-size:10.5pt">打卡</span>
                                <span style="font-family:Calibri; font-size:10.5pt">/</span>
                                <span style="font-family:宋体; font-size:10.5pt">作业</span>
                                <span style="font-family:宋体; font-size:10.5pt">时附加本地图片信息；</span>
                            </li>
                        </ol>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:65.35pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">否</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:37.65pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">是</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:130.3pt">
                        <ol type="1" style="margin:0pt; padding-left:0pt">
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">无法上传本地图片至班级相册</span>
                            </li>
                            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:5.03pt; text-align:justify; text-indent:0pt; widows:0">
                                <span style="font-family:宋体; font-size:10.5pt">无法上传本地图片至通知</span>
                                <span style="font-family:Calibri; font-size:10.5pt">/</span>
                                <span style="font-family:宋体; font-size:10.5pt">打卡</span>
                                <span style="font-family:Calibri; font-size:10.5pt">/</span>
                                <span style="font-family:宋体; font-size:10.5pt">作业</span>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style="height:18pt">
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:44.7pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">麦克风</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:151.05pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">教师发布通知</span>
                            <span style="font-family:Calibri; font-size:10.5pt">/</span>
                            <span style="font-family:宋体; font-size:10.5pt">打卡</span>
                            <span style="font-family:Calibri; font-size:10.5pt">/</span>
                            <span style="font-family:宋体; font-size:10.5pt">作业</span>
                            <span style="font-family:宋体; font-size:10.5pt">时涉及音频资料上传</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:65.35pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">是</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:37.65pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">是</span>
                        </p>
                    </td>
                    <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:2.62pt 5.62pt; vertical-align:bottom; width:130.3pt">
                        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                            <span style="font-family:宋体; font-size:10.5pt">发布通知</span>
                            <span style="font-family:Calibri; font-size:10.5pt">/</span>
                            <span style="font-family:宋体; font-size:10.5pt">打卡</span>
                            <span style="font-family:Calibri; font-size:10.5pt">/</span>
                            <span style="font-family:宋体; font-size:10.5pt">作业</span>
                            <span style="font-family:宋体; font-size:10.5pt">中无法上传音频材料</span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style="margin:0pt; orphans:0; widows:0">
            <a name="OLE_LINK55"></a>
            <a name="OLE_LINK56"></a>
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(三)</span>
            <span style="font-family:宋体; font-size:10.5pt">征得</span>
            <span style="font-family:宋体; font-size:10.5pt">授权同意</span>
            <span style="font-family:宋体; font-size:10.5pt">收集</span>
            <span style="font-family:宋体; font-size:10.5pt">、使用</span>
            <span style="font-family:宋体; font-size:10.5pt">您</span>
            <span style="font-family:宋体; font-size:10.5pt">个人信息的例外</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">您</span>
            <span style="font-family:宋体; font-size:10.5pt">充分了解，</span>
            <span style="font-family:宋体; font-size:10.5pt">根据相关法律法规的规定，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">在以下情形中，我们收集</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">、</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">使用个人</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">信息无需</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">征得您的授权同意</span>
            <span style="font-family:宋体; font-size:10.5pt; text-decoration:underline">：</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">1)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">为履行法定职责或者法定义务所必需；</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">2)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">3)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">所收集的个人信息是您自行向社会公众公开的；</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">4)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">5)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">根据您的要求签订和履行合同所必需的；</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">6)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">用于维护本产品和相关服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:0.3pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">7)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">法律法规规定的其他情形。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(四)</span>
            <span style="font-family:宋体; font-size:10.5pt">个人</span>
            <span style="font-family:宋体; font-size:10.5pt">信息的使用规则</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">我们</span>
                <span style="font-family:宋体; font-size:10.5pt">会根据本政策的约定对所收集的个人信息进行使用。</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">您</span>
                <span style="font-family:宋体; font-size:10.5pt">根据本政策</span>
                <span style="font-family:宋体; font-size:10.5pt">所提供的</span>
                <span style="font-family:宋体; font-size:10.5pt">或</span>
                <span style="font-family:宋体; font-size:10.5pt">同意我们</span>
                <span style="font-family:宋体; font-size:10.5pt">收集的您的个人信息，将在</span>
                <span style="font-family:宋体; font-size:10.5pt">您</span>
                <span style="font-family:宋体; font-size:10.5pt">使用本产品期间持续授权我们</span>
                <span style="font-family:宋体; font-size:10.5pt">使用</span>
                <span style="font-family:宋体; font-size:10.5pt">。在</span>
                <span style="font-family:宋体; font-size:10.5pt">您</span>
                <span style="font-family:宋体; font-size:10.5pt">注销账号</span>
                <span style="font-family:宋体; font-size:10.5pt">并通知我们</span>
                <span style="font-family:宋体; font-size:10.5pt">时，我们</span>
                <span style="font-family:宋体; font-size:10.5pt">会停止</span>
                <span style="font-family:宋体; font-size:10.5pt">使用并删除</span>
                <span style="font-family:宋体; font-size:10.5pt">您</span>
                <span style="font-family:宋体; font-size:10.5pt">的个人信息</span>
                <span style="font-family:宋体; font-size:10.5pt">或</span>
                <span style="font-family:宋体; font-size:10.5pt">进行匿名化处理。</span>
            </li>
            <li style="font-family:Calibri; font-size:10.5pt; margin:0pt 0pt 0pt 12.97pt; orphans:0; padding-left:8.03pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">当我们将您</span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">的个人</span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">二、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何共享、转让、公开披露您的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:-28.35pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(一)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">共享</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">我们不会与</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">任何公司、组织和个人分享您的个人信息，但以下情况除外：</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline"> </span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">在事先获取您明确同意或授权的情况下共享；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">根据适用</span>
                <span style="font-family:宋体; font-size:10.5pt">的</span>
                <span style="font-family:宋体; font-size:10.5pt">法律法规、</span>
                <span style="font-family:宋体; font-size:10.5pt">法律程序的要求</span>
                <span style="font-family:宋体; font-size:10.5pt">、政府主管部门的强制性要求或</span>
                <span style="font-family:宋体; font-size:10.5pt">司法裁决</span>
                <span style="font-family:宋体; font-size:10.5pt">而</span>
                <span style="font-family:宋体; font-size:10.5pt">需要</span>
                <span style="font-family:宋体; font-size:10.5pt">共享；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">在法律法规要求或允许的范围内，为了维护联想</span>
                <span style="font-family:宋体; font-size:10.5pt">、您</span>
                <span style="font-family:宋体; font-size:10.5pt">及其他用户或社会公众的利益、财产或安全免遭损害而有必要提供；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">只有</span>
                <span style="font-family:宋体; font-size:10.5pt">共享您的信息，才能实现我们的</span>
                <span style="font-family:宋体; font-size:10.5pt">产品</span>
                <span style="font-family:宋体; font-size:10.5pt">功能或</span>
                <span style="font-family:宋体; font-size:10.5pt">向您</span>
                <span style="font-family:宋体; font-size:10.5pt">提供您需要的服务；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">与我们</span>
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">的关联公司共享</span>
                <span style="font-family:宋体; font-size:10.5pt">：</span>
                <span style="font-family:宋体; font-size:10.5pt">您的个人信息可能会在我们的关联公司之间共享。我们只会共享必要的个人信息，且这种共享受到本政策所声明目的的约束。我们</span>
                <span style="font-family:宋体; font-size:10.5pt">的</span>
                <span style="font-family:宋体; font-size:10.5pt">关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；</span>
            </li>
        </ol>
        <p style="margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:-28.35pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(二)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">转让</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">事先获得您明确的同意或</span>
                <span style="font-family:宋体; font-size:10.5pt">授权</span>
                <span style="font-family:宋体; font-size:10.5pt">；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">根据适用</span>
                <span style="font-family:宋体; font-size:10.5pt">的</span>
                <span style="font-family:宋体; font-size:10.5pt">法律法规、</span>
                <span style="font-family:宋体; font-size:10.5pt">法律程序的要求</span>
                <span style="font-family:宋体; font-size:10.5pt">、政府主管部门的强制性要求或</span>
                <span style="font-family:宋体; font-size:10.5pt">司法裁决</span>
                <span style="font-family:宋体; font-size:10.5pt">所必须</span>
                <span style="font-family:宋体; font-size:10.5pt">的情况进行转让</span>
                <span style="font-family:宋体; font-size:10.5pt">；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; font-weight:bold; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">在涉及合并、收购或破产清算时，如涉及到个人信息转移时，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:-28.35pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">(三)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">公开披露</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们仅会在以下情况下，公开披露您的个人信息：</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">事先获得您明确的同意或</span>
                <span style="font-family:宋体; font-size:10.5pt">授权</span>
                <span style="font-family:宋体; font-size:10.5pt">；</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">根据适用</span>
                <span style="font-family:宋体; font-size:10.5pt">的</span>
                <span style="font-family:宋体; font-size:10.5pt">法律法规、</span>
                <span style="font-family:宋体; font-size:10.5pt">法律程序的要求</span>
                <span style="font-family:宋体; font-size:10.5pt">、政府主管部门的强制性要求或</span>
                <span style="font-family:宋体; font-size:10.5pt">司法裁决</span>
                <span style="font-family:宋体; font-size:10.5pt">所必须</span>
                <span style="font-family:宋体; font-size:10.5pt">的情况</span>
                <span style="font-family:宋体; font-size:10.5pt">下</span>
                <span style="font-family:宋体; font-size:10.5pt">，</span>
                <span style="font-family:宋体; font-size:10.5pt">我们</span>
                <span style="font-family:宋体; font-size:10.5pt">可能会</span>
                <span style="font-family:宋体; font-size:10.5pt">依据所</span>
                <span style="font-family:宋体; font-size:10.5pt">要求的个人信息类型和披露方式公开披露您的个人信息。</span>
                <span style="font-family:宋体; font-size:10.5pt">在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。</span>
            </li>
        </ol>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">根据法律规定，若我们对个人信息采取技术措施和其他必要措施进行匿名化处理，且数据接收方无法复原并重新识别个人信息主体的，对此类数据的共享、转移、公开披露将无需另行向您通知并征得您的同意。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">三、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">如何存储您的个人信息</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:2.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">存储地点</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">按照法律法规的要求，</span>
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">将在</span>
            <span style="font-family:宋体; font-size:10.5pt">中华人民共和国境内收集和产生的个人信息，存储在中华人民共和国境内。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:2.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">存储时间</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们将只在您的账户有效期内或者在向您提供产品</span>
            <span style="font-family:宋体; font-size:10.5pt">相关</span>
            <span style="font-family:宋体; font-size:10.5pt">服务和技术支持所需的期限内保留您的信息。我们还将在为遵守适用的法律义务、解决争议以及执行协议而必要的期限内保留您的个人信息。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">对于超出期限的个人信息，我们会立即删除或做匿名化处理。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">四、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何保护您的个人信息 </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们将严格按照现行法律法规的要求保护您的个人信息，并且采取一切合理可行的、使用符合业界标准的安全防护以防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在传输敏感信息时，我们使用加密来保护您信息的安全；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。 </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">互联网并非绝对安全的环境，没有任何产品、服务或数据传输可以保证 </span>
            <span style="font-family:Calibri; font-size:10.5pt">100% </span>
            <span style="font-family:宋体; font-size:10.5pt">安全。如果不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您；难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">五、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何使用 Cookie 和同类技术 </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（一）</span>
            <span style="font-family:Calibri; font-size:10.5pt">Co</span>
            <span style="font-family:Calibri; font-size:10.5pt">okie</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">为帮助您</span>
            <span style="font-family:宋体; font-size:10.5pt">获得更好</span>
            <span style="font-family:宋体; font-size:10.5pt">的网站访问体验，联想会使用</span>
            <span style="font-family:Calibri; font-size:10.5pt">C</span>
            <span style="font-family:Calibri; font-size:10.5pt">ookie</span>
            <span style="font-family:宋体; font-size:10.5pt">，例如 </span>
            <span style="font-family:Calibri; font-size:10.5pt">Google Analytics </span>
            <span style="font-family:宋体; font-size:10.5pt">和 </span>
            <span style="font-family:Calibri; font-size:10.5pt">DoubleClick cookie</span>
            <span style="font-family:宋体; font-size:10.5pt">，以存储和帮助跟踪关于您的信息。</span>
            <span style="font-family:Calibri; font-size:10.5pt">C</span>
            <span style="font-family:Calibri; font-size:10.5pt">ookie </span>
            <span style="font-family:宋体; font-size:10.5pt">是从网络服务器发送到您的浏览器并存储在您的计算机或移动</span>
            <span style="font-family:宋体; font-size:10.5pt">设备</span>
            <span style="font-family:宋体; font-size:10.5pt">上的小数据文件，通常包含标识符、站点名称以及一些号码和字符。借助</span>
            <span style="font-family:Calibri; font-size:10.5pt">C</span>
            <span style="font-family:Calibri; font-size:10.5pt">ookie</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">我们能够保存您</span>
            <span style="font-family:宋体; font-size:10.5pt">的</span>
            <span style="font-family:宋体; font-size:10.5pt">相关</span>
            <span style="font-family:宋体; font-size:10.5pt">数据</span>
            <span style="font-family:宋体; font-size:10.5pt">，使得您无需在每次访问时都要重新输入；同时</span>
            <span style="font-family:宋体; font-size:10.5pt">，我们还可</span>
            <span style="font-family:宋体; font-size:10.5pt">能</span>
            <span style="font-family:宋体; font-size:10.5pt">利用上述技术</span>
            <span style="font-family:宋体; font-size:10.5pt">获取</span>
            <span style="font-family:宋体; font-size:10.5pt">的</span>
            <span style="font-family:宋体; font-size:10.5pt">流量</span>
            <span style="font-family:宋体; font-size:10.5pt">模式、访问模式等</span>
            <span style="font-family:宋体; font-size:10.5pt">信息</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">来简化</span>
            <span style="font-family:宋体; font-size:10.5pt">网站浏览</span>
            <span style="font-family:宋体; font-size:10.5pt">及帮助设计我们的网站以让您的体验更高效、更愉悦。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们不会将 </span>
            <span style="font-family:Calibri; font-size:10.5pt">Cookie </span>
            <span style="font-family:宋体; font-size:10.5pt">用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 </span>
            <span style="font-family:Calibri; font-size:10.5pt">Cookie</span>
            <span style="font-family:宋体; font-size:10.5pt">。大部分网络浏览器都设有阻止</span>
            <span style="font-family:Calibri; font-size:10.5pt">Cookie</span>
            <span style="font-family:宋体; font-size:10.5pt">的功能，您可以通过浏览器设置选项清除计算机上保存的所有</span>
            <span style="font-family:Calibri; font-size:10.5pt">Cookie</span>
            <span style="font-family:宋体; font-size:10.5pt">，或</span>
            <span style="font-family:宋体; font-size:10.5pt">使用浏览器的无痕浏览设置来</span>
            <span style="font-family:宋体; font-size:10.5pt">访问</span>
            <span style="font-family:宋体; font-size:10.5pt">我们的网站</span>
            <span style="font-family:宋体; font-size:10.5pt">，但如果您这么做，可能会导致则您需要在每一次访问我们的网站时都需要亲自更改用户设置。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">六、</span>
            <span style="font-family:宋体; font-size:10.5pt">我们如何处理未成年人的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们非常重视对未成年人个人信息的保护。</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">如</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">果您是</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">年满</span>
            <span style="font-family:&amp;quot; font-size:10.5pt; font-weight:bold; text-decoration:underline">14</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">周岁</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">的未成年人，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">在使用本产品前，请您务必在监护人的陪同下阅读本政策，并确保已征得您的监护人明确同意后，方可使用本产品并向我们提供您的个人信息。对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用或披露此信息。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">联想不会寻求收集未满 </span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline">14 </span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">周岁的未成年人的信息。未满</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline">1</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline">4</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline"> </span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">周岁的用户不应通过本产品提供任何信息。如果联想获知未满</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline">1</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline">4</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold; text-decoration:underline"> </span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">周岁的</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">未成年人</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">通过本产品提交了个人信息，我们将会采取适当的步骤尽快删除该信息且不会将该信息用于任何目的。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">对于可能针对未成年人特性而开发或适用的产品和</span>
            <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">/</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">或服务，我们根据相关法律法规的要求制定了</span>
            <a style="color:#0563c1" href="https://www.lenovo.com.cn/statement/privacy_child.html">
                <span style="color:#0563c1; font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">《儿童个人信息保护规则》</span>
            </a>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">或类似文件并会以显著方式提供给您，以便您在启用产品或服务时查阅。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">七、</span>
            <span style="font-family:宋体; font-size:10.5pt">您如何</span>
            <span style="font-family:宋体; font-size:10.5pt">行使管理个人信息的权利</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（一）按照</span>
            <span style="font-family:宋体; font-size:10.5pt">法律</span>
            <span style="font-family:宋体; font-size:10.5pt">、</span>
            <span style="font-family:宋体; font-size:10.5pt">法规及相关国家标准的要求，我们保障您对自己的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt">行使</span>
            <span style="font-family:宋体; font-size:10.5pt">以下权利：</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">查询、</span>
                <span style="font-family:宋体; font-size:10.5pt">更正</span>
                <span style="font-family:宋体; font-size:10.5pt">和补充</span>
                <span style="font-family:宋体; font-size:10.5pt">您的个人信息</span>
                <span style="font-family:宋体; font-size:10.5pt">。</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">除法律</span>
            <span style="font-family:宋体; font-size:10.5pt">法规另</span>
            <span style="font-family:宋体; font-size:10.5pt">有</span>
            <span style="font-family:宋体; font-size:10.5pt">规定外</span>
            <span style="font-family:宋体; font-size:10.5pt">，</span>
            <span style="font-family:宋体; font-size:10.5pt">您有权随时</span>
            <span style="font-family:宋体; font-size:10.5pt">查询</span>
            <span style="font-family:宋体; font-size:10.5pt">您的个人信息。</span>
            <span style="font-family:宋体; font-size:10.5pt">当</span>
            <span style="font-family:宋体; font-size:10.5pt">您发现</span>
            <span style="font-family:宋体; font-size:10.5pt">我们处理的关于您的个人信息有错误时，</span>
            <span style="font-family:宋体; font-size:10.5pt">您</span>
            <span style="font-family:宋体; font-size:10.5pt">可以</span>
            <span style="font-family:宋体; font-size:10.5pt">通过如下方式</span>
            <span style="font-family:宋体; font-size:10.5pt">，查询</span>
            <span style="font-family:宋体; font-size:10.5pt">和修改您的个人信息</span>
            <span style="font-family:宋体; font-size:10.5pt">，具体途径如下</span>
            <span style="font-family:宋体; font-size:10.5pt">：</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">a)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">您</span>
            <span style="font-family:宋体; font-size:10.5pt">的</span>
            <span style="font-family:宋体; font-size:10.5pt">姓名</span>
            <span style="font-family:宋体; font-size:10.5pt">信息：</span>
            <span style="font-family:宋体; font-size:10.5pt">【</span>
            <span style="font-family:宋体; font-size:10.5pt">您可以在应用软件中选择“个人中心”，点击“修改资料”进行姓名修改</span>
            <span style="font-family:宋体; font-size:10.5pt">】</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <a name="_Hlk78353923">
                    <span style="font-family:宋体; font-size:10.5pt">删除您的个人信息</span>
                </a>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">您可以通过如下方式直接删除您的相关个人信息。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">a)</span>
            <span style="width:10.5pt; text-indent:0pt; display:inline-block"></span>
            <span style="font-family:宋体; font-size:10.5pt">您的姓名信息：【</span>
            <span style="font-family:宋体; font-size:10.5pt">您可以在应用软件中选择“个人中心”，点击“修改资料”进行姓名删除</span>
            <span style="font-family:宋体; font-size:10.5pt">】</span>
        </p>
        <p style="margin:0pt 0pt 0pt 42pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="-aw-bookmark-end:_Hlk78353923"></span>
            <span style="font-family:宋体; font-size:10.5pt">在以下情形中，您可以向我们提出删除个人信息的请求： </span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">1)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">如果我们处理个人信息的行为违反法律法规； </span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">2)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">如果我们收集、使用您的个人信息，却未征得您的同意； </span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">3)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">如果我们处理个人信息的行为违反了与您的约定； </span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">4)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">如果您不再使用我们的产品或服务，或您注销了账号； </span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">5)</span>
            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
            <span style="font-family:宋体; font-size:10.5pt">如果我们不再为您提供产品或服务。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">当您从我们的产品中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">注销</span>
                <span style="font-family:宋体; font-size:10.5pt">您的用户账户</span>
            </li>
        </ol>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 10.5pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">如果</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">您希望注销您的用户账户，请您</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">按照软件列出的操作方式或者“如何</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">联系我们</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">”</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">中列出的</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">联系方式向</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">我们提出</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">账户注销要求</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:normal">，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">账户注销成功后，我们将停止为您提供产品或服务，并根据适用法律法规的要求删除您的个人信息或</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">做匿名化处理</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">改变您</span>
                <span style="font-family:宋体; font-size:10.5pt">授权同意的范围</span>
                <span style="font-family:宋体; font-size:10.5pt">或</span>
                <span style="font-family:宋体; font-size:10.5pt">撤回您的授权</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">通常而言，</span>
            <span style="font-family:宋体; font-size:10.5pt">每个业务功能需要一些基本的个人信息才能得以完成</span>
            <span style="font-family:宋体; font-size:10.5pt">（见本政策</span>
            <span style="font-family:宋体; font-size:10.5pt">第一条</span>
            <span style="font-family:宋体; font-size:10.5pt">）。您</span>
            <span style="font-family:宋体; font-size:10.5pt">可以通过删除信息、关闭特定功能、进行隐私设置等方式</span>
            <span style="font-family:宋体; font-size:10.5pt">给予或撤回您的授权同意；</span>
            <a name="_Hlk78353990">
                <span style="font-family:宋体; font-size:10.5pt">您也可按照本文件中列示的联系方式与我们联系。</span>
            </a>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">但</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">请您理解</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权对应的服务，</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">也</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">不再处理您</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">相应</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">的个人信息。但</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">您</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">复制权</span>
            </li>
        </ol>
        <p style="margin:0pt 0pt 0pt 14.2pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">如果您需要您的个人信息的副本，您可以通过本政策中提供联系方式与我们取得联系。在符合法律规定且技术可行的前提下，我们会在收到您联系后的十五个工作日内，于核实您的身份后，向您提供您在我们的服务中的个人信息副本（包括基本资料、身份信息），但法律法规另有规定的或隐私政策另有约定的除外。</span>
        </p>
        <p style="margin:0pt 0pt 0pt 14.2pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">可携带权</span>
            </li>
        </ol>
        <p style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">在法律允许的情况下，您可以请求将您的个人信息转移至指定的个人信息处理者，您还可以要求我们解释对您的个人信息处理规则。您可以通过本政策公布的联系方式与我们联系行使上述权利。在符合法律规定且技术可行的前提下，我们会在收到您联系后的十五个工作日内，于核实您的身份后处理您的申请，但法律法规另有规定的或隐私政策另有约定的除外。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 14.2pt; orphans:0; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">反馈意见及投诉举报权</span>
            </li>
        </ol>
        <p style="margin:0pt 0pt 0pt 14.2pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过本政策第八条中的联系方式，与我们取得联系。我们会在核查后及时向您反馈。</span>
        </p>
        <p style="margin:0pt 0pt 0pt 14.2pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">（二）我们</span>
            <span style="font-family:宋体; font-size:10.5pt">如何</span>
            <span style="font-family:宋体; font-size:10.5pt">响应您的上述请求 </span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">除了以上</span>
            <span style="font-family:宋体; font-size:10.5pt">方式外，您</span>
            <span style="font-family:宋体; font-size:10.5pt">还</span>
            <span style="font-family:宋体; font-size:10.5pt">可以通过</span>
            <span style="font-family:宋体; font-size:10.5pt">“如何</span>
            <span style="font-family:宋体; font-size:10.5pt">联系我们</span>
            <span style="font-family:宋体; font-size:10.5pt">”中列出</span>
            <span style="font-family:宋体; font-size:10.5pt">的方式向我们提出</span>
            <span style="font-family:宋体; font-size:10.5pt">上述请求</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们会在</span>
            <span style="font-family:宋体; font-size:10.5pt">收到您的请求并验证</span>
            <span style="font-family:宋体; font-size:10.5pt">您的身份后</span>
            <span style="font-family:宋体; font-size:10.5pt">的十</span>
            <span style="font-family:宋体; font-size:10.5pt">五个工作日</span>
            <span style="font-family:宋体; font-size:10.5pt">内处理</span>
            <span style="font-family:宋体; font-size:10.5pt">您的请求。</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份中信息）的请求，我们可能会予以拒绝。 </span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">在以下情形中，按照法律法规要求，我们将无法响应您的请求： </span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 36.5pt; orphans:0; padding-left:5.5pt; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">与国家安全、国防安全直接相关的； </span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 36.5pt; orphans:0; padding-left:5.5pt; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">与公共安全、公共卫生、重大公共利益直接相关的； </span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 36.5pt; orphans:0; padding-left:5.5pt; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">与犯罪侦查、起诉、审判和判决执行等直接相关的； </span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 36.5pt; orphans:0; padding-left:5.5pt; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">有充分证据表明您存在主观恶意或滥用权利的； </span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 36.5pt; orphans:0; padding-left:5.5pt; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的； </span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 36.5pt; orphans:0; padding-left:5.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">涉及商业秘密的。</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">八、</span>
            <span style="font-family:宋体; font-size:10.5pt">本政策如何更新</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们保留根据业务调整、法律法规或政策的变化而不时更新或修改本政策的权利。未经您明确同意，我们不会削减您按照本政策所应享有的权利。如果我们对本政策作出任何重大变更，我们会在该等变更生效之前通过客户端推送通知、弹窗形式或者发送电子邮件等方式告知您，以便您能及时了解该等变更。若您不同意修改后的本政策，您有权并应立即停止使用本产品或服务。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; text-indent:-21pt; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">九、</span>
            <span style="font-family:宋体; font-size:10.5pt">如何联系我们</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">如果您对本政策有任何疑问、意见或建议，可以通过以下方式与我们联系：</span>
        </p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:2.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">发送电子</span>
                <span style="font-family:宋体; font-size:10.5pt">邮件至</span>
                <span style="font-family:宋体; font-size:10.5pt">：privacy@lenovo.com</span>
            </li>
            <li style="font-family:宋体; font-size:10.5pt; margin:0pt 0pt 0pt 15.5pt; orphans:0; padding-left:2.5pt; text-align:justify; text-indent:0pt; widows:0">
                <span style="font-family:宋体; font-size:10.5pt">发送信件至</span>
                <span style="font-family:宋体; font-size:10.5pt">：</span>
                <span style="font-family:宋体; font-size:10.5pt">北京市海淀区西北旺东路10号院2号楼法务部，邮编：100094。</span>
            </li>
        </ol>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们设立了个人信息保护专职部门，将在</span>
            <span style="font-family:宋体; font-size:10.5pt">收到您提出的疑问、意见或建议后及时处理您的问题</span>
            <span style="font-family:宋体; font-size:10.5pt">。一般情况下，我们将在十五个工作日内回复。</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">&#xa0;</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:宋体; font-size:10.5pt">我们</span>
            <span style="font-family:宋体; font-size:10.5pt">的注册地址为：</span>
            <span style="font-family:宋体; font-size:10.5pt">【北京市海淀区上地西路</span>
            <span style="font-family:Calibri; font-size:10.5pt">6</span>
            <span style="font-family:宋体; font-size:10.5pt">号</span>
            <span style="font-family:Calibri; font-size:10.5pt">2</span>
            <span style="font-family:宋体; font-size:10.5pt">幢</span>
            <span style="font-family:Calibri; font-size:10.5pt">2</span>
            <span style="font-family:宋体; font-size:10.5pt">层</span>
            <span style="font-family:Calibri; font-size:10.5pt">201-H2-6</span>
            <span style="font-family:宋体; font-size:10.5pt">】</span>
            <span style="font-family:宋体; font-size:10.5pt">。如果您对我们的回复不满意，特别是如果我们的个人信息处理行为损害了您的合法权益，您还可以</span>
            <span style="font-family:宋体; font-size:10.5pt; font-weight:bold; text-decoration:underline">通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案</span>
            <span style="font-family:宋体; font-size:10.5pt">。</span>
            <span style="font-family:宋体; font-size:10.5pt"> </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
            <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span>
        </p>
    </div>
    <!-- <div class="cnzz" style="display: none;">
        <script src="https://s23.cnzz.com/z_stat.php?id=1277655852&web_id=1277655852" language="JavaScript"></script>
    </div> -->
</div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
    
  },
  watch: {
  },
  methods: {
  },
  created() {
    
  },
};
</script>
<style lang="scss">

</style>
